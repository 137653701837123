import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { LangInterceptor } from './interceptors/lang-interceptor';

import { HomeComponent } from './component/home/home.component';
import { HeaderComponent } from './component/common/header/header.component';
import { FooterComponent } from './component/common/footer/footer.component';
import { JapaneseTrainingComponent } from './component/japanese-training/japanese-training.component';
import { RegisterComponent } from './component/register/register.component';
import { HeaderTwoComponent } from './component/common/header-two/header-two.component';
import { FooterTwoComponent } from './component/common/footer-two/footer-two.component';
import { HeadComponent } from './component/head/head.component';
import { JobsInJapanComponent } from './component/jobs-in-japan/jobs-in-japan.component';
import { JobsInJapanSemiComponent } from './component/jobs-in-japan-semi/jobs-in-japan-semi.component';
import { JobsInJapanProfComponent } from './component/jobs-in-japan-prof/jobs-in-japan-prof.component';
import { ResourcingComponent } from './component/resourcing/resourcing.component';
import { ResourcingSemiComponent } from './component/resourcing-semi/resourcing-semi.component';
import { ResourcingProfComponent } from './component/resourcing-prof/resourcing-prof.component';
import { TechnologyComponent } from './component/technology/technology.component';
import { BlogsNewsComponent } from './component/blogs-news/blogs-news.component';
import { ScheduleSessionComponent } from './component/schedule-session/schedule-session.component';
import { LearningComponent } from './component/learning/learning.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { GetJobComponent } from './component/get-job/get-job.component';
import { CandidateDetailsComponent } from './component/candidate-details/candidate-details.component';
import { SoftskillsComponent } from './component/softskills/softskills.component';
import { ItSkillsComponent } from './component/it-skills/it-skills.component';
import { SswComponent } from './component/ssw/ssw.component';
import { LearnchainComponent } from './component/learnchain/learnchain.component';
import { SswSspComponent } from './component/ssw-ssp/ssw-ssp.component';
import { TechnicalTrainingComponent } from './component/technical-training/technical-training.component';
import { ItEngineersComponent } from './component/it-engineers/it-engineers.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { BrowseCandidatesComponent } from './component/browse-candidates/browse-candidates.component';
import { TechServicesComponent } from './component/tech-services/tech-services.component';
import { CustomerComponent } from './component/customer/customer.component';
import { PartnerComponent } from './component/partner/partner.component';
import { EnquireComponent } from './component/enquire/enquire.component';
import { PreloaderComponent } from './component/preloader/preloader.component';
import { PreloaderService } from './services/preloader.service';
import { PreloaderResolver } from './preloader.resolver';
import { RectPageComponent } from './component/rect-page/rect-page.component';

@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent, 
    HomeComponent, JapaneseTrainingComponent, RegisterComponent, HeaderTwoComponent, FooterTwoComponent, HeadComponent,
    JobsInJapanComponent, JobsInJapanSemiComponent, JobsInJapanProfComponent, ResourcingComponent, ResourcingSemiComponent, ResourcingProfComponent, TechnologyComponent, BlogsNewsComponent, ScheduleSessionComponent, LearningComponent, AboutUsComponent, ContactUsComponent, PrivacyPolicyComponent, GetJobComponent, CandidateDetailsComponent, SoftskillsComponent, ItSkillsComponent, SswComponent, LearnchainComponent, SswSspComponent, TechnicalTrainingComponent, ItEngineersComponent, GalleryComponent, BrowseCandidatesComponent, TechServicesComponent, CustomerComponent, PartnerComponent, EnquireComponent, PreloaderComponent, RectPageComponent, 
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    CarouselModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [PreloaderResolver,PreloaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
