import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';
import { PreloaderService } from 'src/app/services/preloader.service';
@Component({
  selector: 'app-rect-page',
  templateUrl: './rect-page.component.html',
  styleUrls: ['./rect-page.component.css']
})
export class RectPageComponent implements OnInit {

	lng = localStorage.getItem('lng');
  customOptionsrect: OwlOptions = {
		loop: true,
		margin: 30,
		nav: false,
		dots: false,
		smartSpeed: 1000,
		autoWidth: true,
		autoplay: true,
		// navText: ['<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 1
			},
			600: {
				items: 1
			},
			800: {
				items: 2
			},
			1024: {
				items: 3
			}
		}
	}
  rect: any;
  rectbanner: any;
  nihon: any;
  constructor(	private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService,
		private sanitizer: DomSanitizer,
		private preloaderService: PreloaderService,) { 

      this.requestService.postrect(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.rect = data.data.reverse();
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);

      this.requestService.postrectbanner(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.rectbanner = data.data.reverse();
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);


      this.requestService.postnihon(1)
			.pipe(first())
			.subscribe(
				data => {
					if (data.status == 1) {
						this.nihon = data.data.reverse();
					} else {
						// this.blogNews =[
						// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
						// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
						// ]
					}
				},
				error => {
				}
			);
    }

  ngOnInit(): void {
  }




}
