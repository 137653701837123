// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl:"http://localhost:4000/api/",
  // base_url_assets:"http://localhost:4200/",
  // ncareers_url: "http://localhost:3000/v1/api/"
  apiUrl: "https://admin.nihonedutech.com/api/",
  base_url_assets: "https://nihonedutech.com/",
  ncareers_backend_url: "https://nihon.ncareers.org:3011/",
  ncareers_frontend_url: "https://nihon.ncareers.org/nCareers/",
  // ncareers_backend_url: "https://mig.ncareers.org:3000/",
  // ncareers_frontend_url: "https://mig.ncareers.org/ncareersv6.1.4/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
